<template>
    <div>
        <!-- Blog Banner -->
        <section class="blog-banner">
            <div class="container">
                <div class="title">
                    <h4>
                        <span>{{ $t('Blog.header') }}</span>
                    </h4>
                    <p>
                        {{ $t('Blog.subtitle') }}
                    </p>
                </div>
            </div>
        </section>
        <!-- Blog Banner End -->

        <!-- Featured Posts -->
        <FeatureArticle
            :featuredArticle="featuredArticle"
            :titleLang="titleLang"
            :textLang="textLang"
        />
        <!-- Featured Posts End -->

        <!-- Blog Main -->
        <section class="blog-main">
            <div class="container">
                <div class="row mx-2">
                    <div class="col-lg-3">
                        <div class="left">
                            <h4>{{ $t('Blog.topic') }}</h4>

                            <ul>
                                <li>
                                    <a @click="searchBlogByCategories">
                                        {{ $t('Blog.all') }}
                                    </a>
                                </li>
                                <template v-if="categories.length > 0">
                                    <li
                                        v-for="(
                                            dataCategories, index
                                        ) in categories"
                                        :key="index"
                                    >
                                        <a
                                            @click="
                                                searchBlogByCategories(
                                                    dataCategories[categoryLang]
                                                )
                                            "
                                        >
                                            {{ dataCategories[categoryLang] }}
                                        </a>
                                    </li>
                                </template>

                                <template v-else>
                                    <li v-for="data in 3" :key="data">
                                        <b-skeleton
                                            animation="wave"
                                            width="25%"
                                        ></b-skeleton>
                                    </li>
                                </template>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-9">
                        <span v-if="article.length > 0">
                            <div
                                class="item"
                                v-for="(articleItem, index) in article"
                                :key="index"
                            >
                                <div class="row align-items-center">
                                    <div class="col-lg-4">
                                        <router-link
                                            :to="{
                                                name: 'blogdetail',
                                                params: {
                                                    slug: articleItem.slug,
                                                },
                                            }"
                                        >
                                            <img
                                                :src="articleItem.image"
                                                class="main"
                                            />
                                        </router-link>
                                    </div>

                                    <div class="col-lg-8">
                                        <div class="text">
                                            <router-link
                                                :to="{
                                                    name: 'blogdetail',
                                                    params: {
                                                        slug: articleItem.slug,
                                                    },
                                                }"
                                            >
                                                <h4
                                                    v-html="
                                                        articleItem[titleLang]
                                                    "
                                                />
                                            </router-link>
                                            <div class="alt">
                                                <div class="user">
                                                    <span>
                                                        {{
                                                            `${articleItem[categoryLang][categoryLang]}`
                                                        }}
                                                    </span>
                                                </div>
                                                <h6
                                                    v-text="
                                                        getDateArticle(
                                                            articleItem.published_at
                                                        )
                                                    "
                                                ></h6>
                                            </div>
                                            <p
                                                v-html="
                                                    getSubstring(
                                                        articleItem[textLang]
                                                    )
                                                "
                                            ></p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </span>

                        <div
                            v-show="searchArticle"
                            class="misc-inner p-2 p-sm-3"
                        >
                            <div
                                v-if="article.length == 0"
                                class="w-100 text-center"
                            >
                                <h2 class="mb-1">
                                    {{ $t('Message.notFound') }} 🕵🏻‍♀️
                                </h2>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        <!-- Blog Main End -->
    </div>
</template>

<script>
import {dateMixins} from '@/mixins/dateMixins';
import useAppConfig from '@core/app-config/useAppConfig';
import axiosIns from '@/libs/axios';
import FeatureArticle from '@/@core/components/Blog/FeatureArticle.vue';

export default {
    mixins: [dateMixins],
    components: {
        FeatureArticle,
    },
    data() {
        return {
            article: [],
            featuredArticle: [],
            langCategory: 'eng_category',
            categories: '',
            config: useAppConfig(),
            searchArticle: false,
        };
    },
    computed: {
        titleLang() {
            if (this.getLang == '') {
                return localStorage.getItem('mainLang')
                    ? 'swe_title'
                    : 'eng_title';
            } else return this.getLang == 'sv' ? 'swe_title' : 'eng_title';
        },
        textLang() {
            if (this.getLang == '') {
                return localStorage.getItem('mainLang')
                    ? 'swe_text'
                    : 'eng_text';
            } else return this.getLang == 'sv' ? 'swe_text' : 'eng_text';
        },
        categoryLang() {
            if (this.getLang == '') {
                return localStorage.getItem('mainLang')
                    ? 'swe_category'
                    : 'eng_category';
            } else
                return this.getLang == 'sv' ? 'swe_category' : 'eng_category';
        },
        searchParams() {
            return this.categoryLang == 'swe_category'
                ? 'swe_category__swe_category'
                : 'eng_category__eng_category';
        },
        getLang() {
            return localStorage.getItem('mainLang');
        },
    },
    methods: {
        getArticle() {
            axiosIns
                .get('/blogs', {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {results} = res.data;
                    let publishedArticle = [];
                    let featureArticle = [];
                    results.forEach(element => {
                        if (element.published == true) {
                            publishedArticle.push(element);
                        }
                        if (element.featured == true)
                            featureArticle.push(element);
                    });
                    this.featuredArticle = featureArticle;
                    this.article = publishedArticle;
                });
        },
        getSubstring(value) {
            return value.substring(0, 60) + '...';
        },
        getCategory() {
            axiosIns
                .get('/blog-categories', {
                    transformRequest: (data, headers) => {
                        delete headers.Authorization;
                        return data;
                    },
                })
                .then(res => {
                    const {results} = res.data;

                    this.categories = results;
                });
        },
        searchBlogByCategories(params) {
            if (typeof params == 'object') {
                this.getArticle();
            } else {
                this.searchArticle = true;
                axiosIns
                    .get('/blogs', {
                        params: {[this.searchParams]: params},
                        transformRequest: (data, headers) => {
                            delete headers.Authorization;
                            return data;
                        },
                    })
                    .then(res => {
                        const {results} = res.data;

                        let publishedArticle = [];

                        results.forEach(element => {
                            if (element.published == true)
                                publishedArticle.push(element);
                        });

                        publishedArticle.length > 0
                            ? (this.article = publishedArticle)
                            : (this.article = '');
                    });
            }
        },
    },
    mounted() {
        this.getArticle();

        this.getCategory();
        
        this.locale = localStorage.getItem('mainLang');
    },
};
</script>

<style lang="scss" scoped>
@import '@/assets/scss/firstlayout/blog.scss';
</style>
